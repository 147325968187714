import React, { Suspense, useState, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { db } from "./firebase/firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "./slices/userSlice";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { updateToastMessage } from "./slices/settingsSlice";
import ReactGA from "react-ga4";
import HomePage from "./pages/HomePage";
import LoginModal from "./components/LoginModal";
import AboutUsPage from "./pages/AboutUsPage";
import Spinner from "./pages/Spinner";
import Header from "./components/Header";
import Glossary from "./pages/Glossary";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import ToastMessage from "./components/ToastMessage";
import "./App.scss";
import UserPage from "./pages/UserPage";
import {
  updateNoResultFoundStatus,
  updateSearchResult,
} from "./slices/searchResultSlice";
import Competition from "./pages/Competition";
import CompetitionResults from "./pages/CompetitionResults";
const AddNewSuggestionPage = lazy(() => import("./pages/AddNewSuggestionPage"));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

function App() {
  const [tabName, setTabName] = useState("home");
  const [open, setOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [selectedWordAndLang, setSelectedWordAndLang] = useState({
    word: "",
    isKannada: false,
  });
  const [isBackSpacePressed, setIsBackSpacePressed] = useState(false);

  const period = {
    startTime: new Date("2024-10-01T00:00:00"),
    endTime: new Date("2025-02-12T23:59:59"),
  };
  const currentDate = new Date();
  const isCompetitionActive =
    currentDate >= period.startTime && currentDate <= period.endTime;

  const dispatch = useDispatch();

  const path = window.location.pathname;
  useEffect(() => {
    if (path !== "/") {
      setSelectedWordAndLang({ word: "", isKannada: false });
    }
    // Tracks active user page visits
    ReactGA.send({ hitType: "pageview", page: path });
    // Track the user page visits
    ReactGA.event({
      category: "Page visit",
      action: "User opened a page",
      label: `Page view tracked: ${path}`,
    });
  }, [path]);

  const stringToBoolean = (string) => {
    if (string === "true") {
      return true;
    } else if (string === "false") {
      return false;
    }
  };

  useEffect(() => {
    const fullURL = window.location.href.split("?");
    if (fullURL.length > 1 && selectedWordAndLang.word === "") {
      const _parameters = fullURL[1]?.split("&");
      if (_parameters[0].split("=")[0] === "word") {
        const _searchedWord = _parameters[0].split("=")[1];
        const _searchedInKannada = stringToBoolean(
          _parameters[1]?.split("=")[1]
        );
        setSelectedWordAndLang({
          word: _searchedWord,
          isKannada: _searchedInKannada,
        });
      }
    }
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(
          db,
          process.env.REACT_APP_USERS_COLLECTION,
          user.uid
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          dispatch(
            updateUserDetails({
              userId: docSnap.data().uid,
              name: docSnap.data().name,
              photoURL: docSnap.data().photoURL,
              emailId: docSnap.data().emailId,
              mobileNumber: user.mobileNumber,
              type: docSnap.data().type,
              apiKey: docSnap.data().apiKey,
              myBookMarks: docSnap.data().myBookMarks,
            })
          );
          dispatch(
            updateToastMessage({
              show: true,
              message: "ನೀವು ಒಳಬಂದಿದ್ದೀರಿ.",
              additionalErrorMessage: [],
              isError: false,
              isInfo: true,
              isSuccess: false,
            })
          );
        } else {
          dispatch(
            updateToastMessage({
              show: true,
              message: "ಬಳಕೆದಾರರ ದಾಖಲೆ ಕಂಡುಬಂದಿಲ್ಲ!",
              isError: true,
              additionalErrorMessage: [],
              isInfo: false,
              isSuccess: false,
            })
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    window?.addEventListener("keydown", (e) => {
      if (e.key === "Backspace") {
        changeBackSpacePressedStatus(true);
        setTimeout(() => {
          changeBackSpacePressedStatus(false);
        }, 100);
      }
    });

    return () => {
      window?.removeEventListener("keydown", (e) => {
        if (e.key === "Backspace") {
          changeBackSpacePressedStatus();
        }
      });
    };
  });

  const changeBackSpacePressedStatus = (value) => {
    setIsBackSpacePressed(value);
  };

  const handleTab = (path, tab, navigate, changeTab = true) => {
    if (changeTab) {
      setTabName(tab);
      navigate(path);
    } else {
      setTabName(tab);
    }
    if (open) {
      handleSideMenuToggle();
    }
    if (tab === "home") {
      setSelectedWordAndLang({
        word: "",
        isKannada: false,
      });
      dispatch(updateSearchResult([]));
      dispatch(updateNoResultFoundStatus(false));
      const searchContainer =
        document.getElementsByClassName("search-container")[0];
      if (searchContainer && searchContainer.style) {
        searchContainer.style.marginTop = "30vh";
        searchContainer.style.transition = "1s";
      }
    }
  };

  const handleSideMenuToggle = () => {
    setOpen(!open);
  };

  const toggleLoginModal = () => {
    setLoginModalOpen(!loginModalOpen);
    if (open) {
      setOpen(!open);
    }
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <div className="App">
          <BrowserRouter>
            <Header
              tabName={tabName}
              open={open}
              handleTab={handleTab}
              handleSideMenuToggle={handleSideMenuToggle}
              toggleLoginModal={toggleLoginModal}
            />
            <Routes>
              <Route exact path="/about-us" element={<AboutUsPage />} />
              <Route
                exact
                path="/glossary"
                element={
                  <Glossary setSelectedWordAndLang={setSelectedWordAndLang} />
                }
              />
              <Route
                exact
                path="/add-new-suggestion"
                element={
                  <AddNewSuggestionPage toggleLoginModal={toggleLoginModal} />
                }
              />
              <Route
                exact
                path="/user/:userId"
                element={
                  <UserPage setSelectedWordAndLang={setSelectedWordAndLang} />
                }
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={<TermsAndConditionsPage />}
              />
            /*  {isCompetitionActive && (
                <Route exact path="/competition" element={<Competition />} />
              )} */
              {isCompetitionActive && (
                              <Route exact path="/competitionResults" element={<CompetitionResults />} />
                            )}
              <Route
                exact
                path="/"
                element={
                  <HomePage
                    selectedWordAndLang={selectedWordAndLang}
                    isBackSpacePressed={isBackSpacePressed}
                    setSelectedWordAndLang={setSelectedWordAndLang}
                    isCompetitionActive={isCompetitionActive}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <LoginModal
              loginModalOpen={loginModalOpen}
              toggleLoginModal={toggleLoginModal}
            />
            <ToastMessage />
          </BrowserRouter>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
