import React from "react";
import "./CompetitionResults.scss";

const Competition = () => {
  return (
    <div className="competition-wrapper">
      <div className="competition-section">
        <h1>ತಿಳಿಪದ ಪೈಪೋಟಿ</h1>
        <br />
        <p style={{ fontWeight: "500", margin: "0 10px" }}>
          ಮೊಟ್ಟ ಮೊದಲಿಗೆ, ತಿಳಿಪದ ಪೈಪೋಟಿಯಲ್ಲಿ ಪಾಲ್ಗೊಂಡ ಎಲ್ಲರಿಗೂ ತುಂಬು ಮನದ ಧನ್ಯವಾದಗಳು. ನಾಡಿನೆಲ್ಲೆಡೆಯಿಂದ ಅಷ್ಟೇ ಅಲ್ಲದೇ ಬೇರೆ ರಾಜ್ಯ ಮತ್ತು ದೇಶಗಳಿಂದ ಪದಕಟ್ಟಣೆಗಾರರು ಈ ಪೈಪೋಟಿಯಲ್ಲಿ ಪಾಲ್ಗೊಂಡಿದ್ದು ನಲಿವಿನ ಸಂಗತಿ. ಹೊಸದಾದ, ಬೆರಗುಗೊಳಿಸುವ, ಮುದನೀಡುವ ಹಲವಾರು ಪದಗಳು ಈ ಪೈಪೋಟಿಗೆ ಹರಿದುಬಂದಿದ್ದು, ಅವುಗಳು ಕನ್ನಡದ ಕಸುವನ್ನು ಎತ್ತಿ ತೋರಿಸುವಂತಿವೆ.
          ಹಲವಾರು ಒಳ್ಳೊಳ್ಳೆಯ ಪದಗಳನ್ನು ಕಟ್ಟಿದ ಪದಕಟ್ಟುಗರಲ್ಲಿ ಕೆಲವರನ್ನು ಬಹುಮಾನಕ್ಕೆ ಆರಿಸುವುದು ತುಂಬಾ ಸವಾಲಿನ ಕೆಲಸ. ಸುಲಭವಾಗಿ ತಿಳಿಯುವ ಕನ್ನಡ ಬೇರು ಪದಗಳ ಬಳಕೆ, ಪದಗಳ ಗುಣಮಟ್ಟ, ಪದಕಟ್ಟಣೆಯಲ್ಲಿರುವ ಹೊಸತನ ಮತ್ತು ಇಂಗ್ಲಿಶ್ ಪದವೊಂದಕ್ಕೆ ಕೊಟ್ಟ ಪದಗಳ ಆಯ್ಕೆಗಳನ್ನು ಆಧರಿಸಿ ಈ ಪೈಪೋಟಿಯಲ್ಲಿ ಪದಗಳನ್ನು ಒರೆಹಚ್ಚಲಾಯಿತು.
          ಬಹುಮಾನಗಳಿಗೆ ಆಯ್ಕೆಯಾಗಿರುವ ಪದಕಟ್ಟಣೆಗಾರರಿಗೆ ನಲಿವಿನ ಹಾರೈಕೆಗಳು. ಹಾಗೆನೇ ಈ ಸಲದ ಪೈಪೋಟಿಯಲ್ಲಿ ಬಹುಮಾನ ಪಡೆಯದ ಪದಕಟ್ಟಣೆಗಾರರು ಬೇಸರ ಪಟ್ಟುಕೊಳ್ಳುವುದು ಬೇಡ. ನಿಮ್ಮೆಲ್ಲರ ಪಾಲ್ಗೊಳ್ಳುವಿಕೆ ನಮ್ಮ ತಂಡಕ್ಕೆ ಹೊಸ ಹುರುಪು, ಕಸುವು ತುಂಬಿದೆ.
        </p>
        <br />
        <h3>ಇಗೋ ಇಲ್ಲಿದೆ ನೀವೆಲ್ಲರೂ ಕಾಯುತ್ತಿರುವ ಪೈಪೋಟಿಯ ಫಲಿತಾಂಶ;<br /><br /> </h3>
        <h3>ಮೊದಲ ಬಹುಮಾನ (10,000 ರೂಪಾಯಿಗಳು):</h3>
        <div class="ranks">ಜೀವನ್ ಎ</div><br />
        <h3>ಎರಡನೆಯ ಬಹುಮಾನ (7000 ರೂಪಾಯಿಗಳು):</h3>
        <div class="ranks">ವೆಂಕಟೇಶ ಪಿ. ಮರಕಂದಿನ್ನಿ</div><br />
        <h3>ಮೂರನೆಯ ಬಹುಮಾನ (5000 ರೂಪಾಯಿಗಳು):</h3>
        <div class="ranks">ಪೃಥ್ವಿ ವಲ್ಲಭ</div><br />
        <div> ಮೇಲೆ ತಿಳಿಸಿದಂತೆ, ಈ ಪೈಪೋಟಿಗೆ ಸೊಗಸಾದ ಪದಗಳ ರಾಶಿಯೇ ಹರಿದುಬಂದಿದ್ದು, ಇನ್ನೂ 5 ಪದಕಟ್ಟಣೆಗಾರರನ್ನು ಬೆಂಬಲ ಬಹುಮಾನಕ್ಕೆ ಇಲ್ಲಿ ಆಯ್ಕೆ ಮಾಡಲಾಗುತ್ತಿದೆ. </div>  <br />
        <h3>ಬೆಂಬಲ ಬಹುಮಾನಗಳು (ತಲಾ 2000 ರೂಪಾಯಿಗಳು): </h3> <br />
        <ol class="ranks">
          <li>
            ದೀಪು
          </li>
          <li>
            ಸಂದೀಪ ಪಾಟೀಲ
          </li>
          <li>
            ಹರ್ಷಕುಮಾರ್‌ ಕುಗ್ವೆ
          </li>
          <li>
            ವಿಗ್ನೇಶ ದತ್ತಾತ್ರಯ ನಾಯ್ಕ
          </li>
          <li>
            ಕಣಾದ ಎಂ ಆರ್
          </li>
        </ol>
        <br />
        <h3> ಹಾಗೆನೇ ಈ ಕೆಳಗಿನ ಪದಕಟ್ಟಣೆಗಾರರು ಕಟ್ಟಿದ ಪದಗಳು ಮೆಚ್ಚುಗೆ ಪಡೆದವು, </h3> <br />
        <ul class="ranks">
          <li> ವಿಜಯಕುಮಾರ ಕೆ.ಎನ್
          </li>
          <li> ಮಂಜಪ್ಪ ಬಿ.ಎಸ್
          </li>
          <li> ಸುಧೀರ್ ಹುಲ್ಲುಮನೆ
          </li>
          <li> ಶೈಲಜಾ. ಎಸ್
          </li>
          <li> ಕೆ. ಭಾಸ್ಕರ ಹೆಬ್ಬಾರ
          </li>
          <li> ಪ್ರಕಾಶ ರಾಜಗೋಳಿ
          </li>
          <li> ಪ್ರಕಾಶ ರಾಜಗೋಳಿ </li>
          <li> ಪವನ್ ಕುಮಾರ್ ರಾಮಣ್ಣ </li>
          <li> ರುದ್ರಮೂರ್ತಿ </li>
          <li> ವಿಕಾಸ್ ಹೆಗಡೆ </li>
          <li> ಡಾ. ಬಿ.ಆರ್. ಸತ್ಯನಾರಾಯಣ </li>
          <li> ದುಂಡಪ್ಪ ಗಂ ಬಾಡಗಿ </li>
          <li> ಸುಮ ಕೆ ಹೆಚ್  </li>
          <li> ರೇಖಾ ಹೆಗಡೆ ಬಾಳೇಸರ  </li>
          <li> ಅನುಪಮ ಪ್ರಬುದ್ದ  </li>
        </ul>
        <br />
        <h3>ಹಾಗೆನೇ ಇವರು ಕೂಡ,</h3> <br />
        <ul class="ranks">
          <li> ಶಶಿಧರ ಎಂ.ಎಸ್.  </li>
          <li> ಗಣೇಶಪ್ರಸಾದ್‌ ಭಟ್‌  </li>
          <li> ಡಾ. ನಾಗರಾಜ್ ಕೆ. ಸಿ.  </li>
          <li> ಐಶ್ವರ್ಯ   </li>
          <li> ಕೆ.ಆರ್.ವೆಂಕಟೇಶ್  </li>
          <li> ಧನುಜ ಜೆ  </li>
          <li> ರೂಪಶ್ರಿ ಎಂ ಆರ್  </li>
          <li> ಎಚ್.ಕೆ.ಶರತ್  </li>
          <li> ಅರ್ಚನ ಶಂಕರ್  </li>
          <li> ಹಸೀನ ಪರ್ವೀನ್ ಹೆಚ್  </li>
          <li> ವಿನೋದ.ಸೋಮಪ್ಪ.ಗೂಳಪ್ಪನವರ  </li>
          <li> ಕೀರ್ತಿ ಹುಂಡೇಕರ್  </li>
          <li> ಡಾ. ವೀಣಾ. ಎಸ್. ಕೆ.  </li>
          <li> ಸರೀನ್‌  ತಾಜ್‌  </li>
          <li> ಎಸ್. ಆರ್. ಹಿರೇಮಠ </li>
          <li> ಶೈಲಜಾ ವಿ ಶೆಟ್ಟಿ  </li>
          <li> ಸೌಮ್ಯ ಸರಸ್ವತಿ </li>
        </ul>  <br />
        <div class="rankDesc">ಮತ್ತು ಇನ್ನು ಹಲವು ಪದಕಟ್ಟಣೆಗಾರರು ಮೆಚ್ಚುಗೆ ಪಡೆದರು.</div>
        <br />
        <h3> ವಿಶೇಷ ಧನ್ಯವಾದಗಳು :  </h3><br />
        <h4> 1.	ನಮ್ಮ ಕನ್ನಡ ಶಾಲೆ ಜರ್ಮನಿ:  </h4> <br />
        <div> ಈ ಪೈಪೋಟಿಗೆ ನಲ್ಮೆಯಿಂದ ತಮ್ಮ ಪದಗಳನ್ನು ಕಳುಹಿಸಿಕೊಟ್ಟ, ಜರ್ಮನಿಯಲ್ಲಿರುವ ನಮ್ಮ ಕನ್ನಡ ಶಾಲೆಯ ಮಕ್ಕಳು ಮತ್ತು ಶಿಕ್ಷಕರಿಗೆ ತುಂಬ ಮನದ ವಿಶೇಷ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಪಾಲ್ಗೊಳ್ಳುವಿಕೆ ಈ ಪೈಪೋಟಿಗೆ ಒಂದು ಮೆರುಗು ತಂದುಕೊಟ್ಟಿತು.</div>
        <br />
        <h4> 2.	ಅಜಿತ ಕುಲಕರ್ಣಿ :  </h4> <br />
        <div> ಕನ್ನಡದ್ಡೇ ಆದ ಹೊಸಪದಗಳನ್ನು ಕಟ್ಟುವುದರಲ್ಲಿ ತಮ್ಮದೇ ಅಚ್ಚು ಮೂಡಿಸಿರುವ ಇವರು ಪೈಪೋಟಿಯಲ್ಲಿ ಪಾಲ್ಗೊಂಡಿದ್ದು ನಲಿವಿನ ಸಂಗತಿ.</div>
        <br />
        <h4> 3.	ಜಯಪ್ರಕಾಶ ಪುಟ್ಟಸ್ವಾಮಿ: </h4> <br />
        <div> ಅಜೈಲ್ ಜಗತ್ತಿನಲ್ಲಿ ತುಂಬಾ ಹೆಸರುವಾಸಿಯಾಗಿರುವ, ಕನ್ನಡದ ಕೆಲಸಗಳಲ್ಲಿ ಹಲವು ವರುಶಗಳಿಂದ ತೊಡಗಿಕೊಂಡಿರುವ ಇವರು ಪೈಪೋಟಿಯಲ್ಲಿ ಪಾಲ್ಗೊಂಡು, ತಮ್ಮ ಬೆಂಬಲವನ್ನು ತೋರಿಸಿದ್ದಕ್ಕೆ ತುಂಬಾ ಧನ್ಯವಾದಗಳು. </div>
        <br />
      </div>
    </div>
  );
};

export default Competition;
